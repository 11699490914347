<template>
  <!-- <keep-alive> -->
  <router-view></router-view>
  <!-- </keep-alive> -->
</template>
<script>
import authRouter from "mixins/authRouter"
export default {
  mixins: [authRouter],
}
</script>