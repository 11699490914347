export default {
  beforeRouteEnter (to, from, next) {
    try {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo && to.meta.auth) {
        if (to.meta.auth.indexOf(userInfo.role) > -1) {
          next('/home')
        } else {
          next()
        }
      } else {
        next()
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}
